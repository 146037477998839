import { useGetKeyResult } from '@/hooks/key_result/key_result';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { ConfidenceChip } from '../ConfidenceChip';
import { KeyResultCheckInStatus } from '../KeyResultCheckInStatus';
import { KeyResultLastCheckIn } from '../KeyResultLastCheckIn';
import { KeyResultOwner } from '../KeyResultOwner';
import { KeyResultProgressBar } from '../KeyResultProgressBar';
import { Loading } from '../generic/Loading';

export function KeyResultListItem({kr, index}) {
  const router = useRouter();
  const {data: krInstance, isLoading} = useGetKeyResult(kr);
  if (isLoading) return <Loading />;
  if (!krInstance) return <Loading />;
  return (
    <Stack width="100%" key={krInstance.id} paddingTop={index === 0 ? 1 : 2}>
      {index !== 0 && <Divider sx={{mt: '5px', mb: '5px'}} />}
      <Button
        sx={{width: '100%', paddingX: '16px'}}
        onClick={(e) => {
          router.push(`/key-result/${krInstance.id}`);
          e.stopPropagation();
        }}
      >
        <Stack
          direction={{xs: 'column', sm: 'row'}}
          gap={2}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack gap={2}>
            <Typography variant="body2" align="left" fontWeight={500}>
              {krInstance.name}
            </Typography>
            <Stack direction="row" gap={1} alignItems="flex-end">
              <ConfidenceChip confidence={krInstance.current_confidence} />
              <KeyResultCheckInStatus progressHistory={krInstance.progress_histories} />
              <KeyResultLastCheckIn checkInDate={krInstance.last_checkin_date} />
            </Stack>
          </Stack>
          <Stack direction="row" gap={4} alignItems="center">
            <KeyResultProgressBar keyResult={krInstance} />
            <KeyResultOwner owner_id={krInstance?.owner_id} />
          </Stack>
        </Stack>
      </Button>
    </Stack>
  );
}
