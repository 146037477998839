import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Stack} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {DeleteConfigButton} from '../DeleteConfigButton';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },

  '& .MuiPaper-root': {
    width: '100%',
  },
}));

function ActionsButton(props) {
  const {children, object, deleteMutation} = props;
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {onClose: handleClose})
  );

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        handleEditClose();
      }, 500);
    }
  }, [open]);

  const showOptions = !editOpen;

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {showOptions && (
          <>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Stack padding={4} paddingBottom={0} gap={2}>
                <Button
                  onClick={handleEditOpen}
                  startIcon={<EditIcon />}
                  size="large"
                  variant="outlined"
                  color="secondary"
                >
                  Editar
                </Button>
                <DeleteConfigButton
                  object={object}
                  deleteMutation={deleteMutation}
                  handleCloseModal={handleClose}
                >
                  Excluir
                </DeleteConfigButton>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancelar
              </Button>
            </DialogActions>
          </>
        )}
        {editOpen && childrenWithProps}
      </BootstrapDialog>
    </>
  );
}

export default ActionsButton;
