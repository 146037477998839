import {Box, CircularProgress, Typography} from '@mui/material';

function CircularProgressPersonalized(props) {
  const {progress} = props;
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress
        variant="determinate"
        value={100}
        sx={{
          color: '#129072',
          opacity: 0.2,
        }}
        size={50}
      />
      <CircularProgress
        variant="determinate"
        color="success"
        value={progress > 100 ? 100 : progress}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        size={50}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="body2"
          component="div"
          color="text.secondary"
        >{`${Math.round(progress)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressPersonalized;
