import { WithSessionRestriction } from '@/components';
import { KeyResultsInsights } from '@/components/KeyResultsInsights';
import { LoadingAdian } from '@/components/LoadingAdian';
import { OkrCard } from '@/components/OkrCard';
import { OkrListHeader } from '@/components/OkrListHeader';
import { OkrListOrder } from '@/components/OkrListOrder';
import { OkrListOverview } from '@/components/OkrListOverview';
import MainLayout from '@/components/layouts/mainlayout';
import { useGetObjectives } from '@/hooks/objective';
import Stack from '@mui/material/Stack';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

function HomePage() {
  const {data: session} = useSession();
  const router = useRouter();

  const [params, setParamsWithoutRouter] = useState({
    owner_id: router.query.owner_id || '',
    area_id: router.query.area_id || '',
    period_id: router.query.period_id || '',
  });

  const setParam = (param, value) => {
    const newParams = {...params, [param]: value};
    router.replace({
      query: newParams,
    });
    setParamsWithoutRouter(newParams);
  };

  useEffect(() => {
    setParamsWithoutRouter({
      owner_id: router.query?.owner_id || '',
      area_id: router.query?.area_id || session?.user?.area || '',
      period_id: router.query?.period_id || session?.user?.company?.current_period || '',
    });
  }, [session, JSON.stringify(router.query)]);

  const [objectives, setObjectives] = useState([]);

  const {data, isLoading, isError} = useGetObjectives(params);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data) &&
      JSON.stringify(objectives) != JSON.stringify(data)
    ) {
      setObjectives(
        data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );
    }
  }, [JSON.stringify(data)]);

  if (isError) return <p>Error fetching objectives!</p>;

  return (
    <MainLayout>
      <Stack
        maxWidth={1270}
        justifyContent="center"
        sx={{margin: '0 auto', marginBottom: '24px'}}
        gap={3}
      >
        <OkrListHeader setParam={setParam} params={params} />
        {!isLoading ? (
          <Stack
            direction={{xs: 'column', md: 'row'}}
            gap={4}
            justifyContent="center"
          >
            <Stack gap={2} width={{xs: '100%', md: '50%'}}>
              <Stack gap={1}>
                <OkrListOrder setObjective={setObjectives} />
                {objectives.map((objective, index) => (
                  <OkrCard key={index} objective={objective} />
                ))}
              </Stack>
            </Stack>
            <Stack gap={2} width={{xs: '100%', md: '50%'}}>
              <OkrListOverview objectives={objectives} />
              <KeyResultsInsights params={params} /> 
            </Stack>
          </Stack>
        ) : (
          <LoadingAdian
            title="carregando..."
            subtitle="isso pode levar alguns segundos"
          />
        )}
      </Stack>
    </MainLayout>
  );
}

export default WithSessionRestriction(HomePage);
