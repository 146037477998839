import { Loading } from '@/components/generic/Loading';
import { KeyResultListItem } from '@/components/KeyResultsListItem';
import {
  useGetLateCheckInKeyResults,
  useGetProgessKeyResults,
} from '@/hooks/key_result/key_result';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

export function KeyResultsInsights(props) {
  const filterParams = props.params;
  return (
    <>
      <Typography variant="title-large">Ações Inteligentes:</Typography>
      <Accordion disableGutters TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="title-medium">Check-ins atrasados</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LateCheckInList filterParams={filterParams} />
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          aria-controls="panel2-content"
          id="panel2-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="title-medium">Progresso Baixo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ProgressoBaixoList filterParams={filterParams} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function LateCheckInList(props) {
  const {filterParams} = props;

  const {data: keyResultsLateCheckIn, isLoading} = useGetLateCheckInKeyResults(filterParams);

  if (isLoading) return <Loading />;

  return (
    <>
      {keyResultsLateCheckIn?.map((kr, index) => (
        <KeyResultListItem kr={kr.id} index={index} key={index} />
      ))}
      {keyResultsLateCheckIn?.length === 0 && (
        <Typography variant="body-medium">Nenhum check-in atrasado encontrado</Typography>
      )}
    </>
  );
}

function ProgressoBaixoList(props) {
  const {filterParams} = props;

  const {data: keyResultsProgress, isLoading} = useGetProgessKeyResults(filterParams);

  if (isLoading) return <Loading />;

  return (
    <>
      {keyResultsProgress?.map((kr, index) => (
        <KeyResultListItem kr={kr.id} index={index} key={index} />
      ))}
      {keyResultsProgress?.length === 0 && (
    <Typography variant="body-medium">Nenhum progresso baixo encontrado</Typography>
      )}
    </>
  );
} 
