import { useCurrentCompany, useGetAreas, useGetPeriods, useUsers } from '@/hooks';
import formatOptions from '@/util/formatOptions';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Form } from '../generic/forms/Form';
import { SelectSingleInput } from '../generic/forms/FormFields';

function OkrListHeader(props) {
  const {setParam, params} = props;
  const {data: areaResults} = useGetAreas();
  const {data: session} = useSession();
  const {data: company} = useCurrentCompany();
  const {data: periodsResults} = useGetPeriods();
  const {data: users} = useUsers();
  const [currentArea, setCurrentArea] = useState();

  function handleChange(param, value) {
    if (value || (value !== undefined && value === '')) {
      setParam(param, value);
    }
  }

  useEffect(() => {
    let area = undefined;
    if (params.area_id) {
      area = areaResults?.find((area) => area.id == params.area_id);
      if (area && JSON.stringify(currentArea) != JSON.stringify(area))
        setCurrentArea(area);
    }
    if (!area && session?.user?.company) {
      if (currentArea?.icon != '/png/areas_icons/company.png')
        setCurrentArea({
          id: session.user.company.id,
          name: session.user.company.name,
          icon: '/png/areas_icons/company.png'
        });
    }
  }, [areaResults, session, params.area_id, company]);

  if (!currentArea) return null;

  return (
    <Card sx={{padding: '16px'}}>
      <Grid container>
        <Grid item xs={12} md={5} sx={{mb: "8px"}}>
          <Stack direction="row" gap={3} alignItems="center">
            <Image
              alt={currentArea.name}
              src={currentArea.icon || '/png/areas_icons/default.png'}
              width={64}
              height={64}
            />
            <Stack gap={2}>
              <Typography variant="display-small" color="#474D66">
                {currentArea.name}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
        <Form>
          <Stack
            direction='row'
            gap={3}
            height="100%"
            margin="0 auto"
            alignItems="center"
          >
            <SelectSingleInput
              options={formatOptions(periodsResults)}
              name="period_id"
              sx={{width: '200px'}}
              label="Ciclo"
              initialValue={params?.period_id}
              changeListener={(value) => {
                handleChange('period_id', value);
              }}
            />
            <SelectSingleInput
              options={formatOptions(areaResults)}
              name="area_id"
              label="Area"
              sx={{width: '200px'}}
              initialValue={params?.area_id}
              changeListener={(value) => {
                handleChange('area_id', value);
              }}
            />
            <SelectSingleInput
              options={formatOptions(users)}
              name="owner"
              label="Dono"
              sx={{width: '200px'}}
              initialValue={params?.owner_id}
              changeListener={(value) => {
                handleChange('owner_id', value);
              }}
            />
          </Stack>
        </Form>
        </Grid>
      </Grid>
    </Card>
  );
}

export default OkrListHeader;
