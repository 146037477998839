import { Card, Stack, Typography, colors } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { KeyResultCheckInStatus } from '../KeyResultCheckInStatus';

function getLast7Weeks() {
  const dates = [];
  let currentDate = dayjs();

  for (let i = 0; i < 6; i++) {
    dates.push(toIsoWeekString(currentDate));
    currentDate = currentDate.subtract(1, 'week');
  }

  return dates.reverse();
}

function calculateWeeklyProgress(objectives) {
  const last7Weeks = getLast7Weeks();
  const weeklyProgressByObjective = [];

  objectives.forEach((objective) => {
    const weeklyProgress = [];
    for (let index = 0; index < last7Weeks.length; index++) {
      const week = last7Weeks[index];
      const isFirstWeek = weeklyProgress.length === 0;
      const thisWeekHistory = objective.progress_histories?.find(progress => progress.week === week);
      if (thisWeekHistory) {
        weeklyProgress.push(thisWeekHistory.progress);
      } else {
        if (isFirstWeek) {
          weeklyProgress.push(getFirstWeekProgress(objective, week));
        } else {
          weeklyProgress.push(weeklyProgress[index - 1]);
        }
      }
    };
    weeklyProgressByObjective.push(weeklyProgress);
  });

  const weeklyProgress = weeklyProgressByObjective.reduce((acc, curr) => {
    return acc.map((value, index) => value + curr[index]);
  }, last7Weeks.map(() => 0));

  return weeklyProgress.map((progress, index) => ({
    name: toWeekString(getDateOfWeek(last7Weeks[index])),
    progress: progress / objectives.length,
  }));
}

function getFirstWeekProgress(objective, week) {
  if (!objective.progress_histories?.length) {
    return 0;
  }
  let progress = 0;
  let currIndex = 0;
  while (currIndex < objective.progress_histories.length && week >= objective.progress_histories[currIndex].week) {
    progress = objective.progress_histories[currIndex].progress;
    currIndex++;
  }
  return progress;
}

function OkrListOverview(props) {
  const {objectives} = props;

  const averageProgress =
    objectives?.reduce((acc, objective) => acc + objective.progress, 0) /
    objectives?.length;

  const dataMemoized = useMemo(
    () => [
      ...calculateWeeklyProgress(objectives),
      {name: 'hoje', progress: Math.round(averageProgress)},
    ],
    [averageProgress, objectives]
  );

  if (!objectives.length) {
    return null;
  }

  return (
    <Card sx={{paddingY: '24px', paddingX: '16px'}}>
      <Stack gap={2}>
        <Stack justifyContent="center">
          <Typography variant="label-large" textAlign="center">
            Visão geral
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={2}>
          <Stack gap={2} justifyContent="center">
            <Typography
              variant="display-small"
              color={colors.green['A700']}
              textAlign="center"
            >
              {Math.round(averageProgress)}%
            </Typography>
            <Stack gap={1} alignItems="center">
              <KeyResultCheckInStatus progressHistory={dataMemoized} />
              <Typography
                variant="body-small"
                color={colors.blueGrey['A400']}
                sx={{width: '70px'}}
                textAlign="center"
              >
                Em relação a última semana
              </Typography>
            </Stack>
          </Stack>
          <Stack
            gap={0.5}
            justifyContent="center"
            sx={{fontSize: '12px', color: colors.grey['500']}}
          >
            <Typography variant="body-small" textAlign="center">
              Progresso por semana
            </Typography>
            <LineChart width={340} height={230} data={dataMemoized}>
              <CartesianGrid vertical={false} />
              <XAxis dataKey="name" />
              <YAxis
                type="number"
                domain={[0, 100]}
                tickCount={6}
                axisLine={false}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="progress"
                stroke={colors.blue['A700']}
                strokeWidth={2}
                activeDot={{r: 8}}
              />
            </LineChart>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

export default OkrListOverview;

function toIsoWeekString(date) {
  var datejs = dayjs(date);
  return datejs.year().toString() + "-W" + datejs.isoWeek().toString();
}


function getDateOfWeek(weekString) {
  const year = weekString.slice(0, 4);
  const week = weekString.slice(6);
  var d = (1 + (parseInt(week) - 1) * 7); // 1st of January + 7 days for each week

  return new Date(parseInt(year), 0, d);
}

function toWeekString(date) {
  const datejs = dayjs(date);
  let monthStart = datejs.date(1);
  if (monthStart.day() === 0 || monthStart.day() === 6) {
    monthStart = monthStart.add(2, 'day');
  }
  if (datejs.add(1, 'month').date(1).isoWeek() === datejs.isoWeek()) {
    return datejs.add(1, 'month').format("MMM")+"-S1"
  }
  return datejs.format("MMM")+"-S"+(datejs.isoWeek() - monthStart.isoWeek()+1)
}
