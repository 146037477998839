import {useGetUserDetails} from '@/hooks/useGetUserDetails';
import {Stack, Typography} from '@mui/material';
import Image from 'next/image';

function KeyResultOwner(props) {
  const {owner_id} = props;

  const owner = useGetUserDetails(owner_id ? parseInt(owner_id) : null);

  if (owner.isLoading)
    return (
      <Stack width="100%" alignItems="center">
        <Image src="/svg/loading.svg" alt="loading" width={50} height={50} />
      </Stack>
    );

  if (!owner.data) {
    return (
      <Stack justifyContent="center" alignItems="center">
        <Image
          alt="default-image"
          src="/png/avatar.png"
          width={24}
          height={24}
        />
        <Typography
          fontWeight={600}
          fontSize={8}
          color="gray"
          maxWidth={55}
          textAlign="center"
          lineHeight="16px"
        >
          Sem dono
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack justifyContent="center" alignItems="center">
      <Image alt="default-image" src="/png/avatar.png" width={24} height={24} />
      <Typography
        fontWeight={600}
        fontSize={8}
        color="gray"
        maxWidth={55}
        textAlign="center"
        lineHeight="16px"
      >
        {owner.data?.name}
      </Typography>
    </Stack>
  );
}

export default KeyResultOwner;
