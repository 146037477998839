import {LinearProgress, Stack, Typography} from '@mui/material';

function KeyResultProgressBar(props) {
  const {keyResult} = props;
  const {target_value, base_value, progress} = keyResult;

  const color = progress < 50 ? 'error' : progress < 70 ? 'ochre' : 'success';

  return (
    <Stack alignItems="end" sx={{minWidth: '100px'}}>
      <Typography variant="body2" fontWeight={600} color={color + '.main'}>
        {progress}%
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        color={color}
        sx={{height: 6, borderRadius: 8, width: '100%'}}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        paddingTop={0.5}
      >
        <Typography fontWeight={600} fontSize={8} color="gray">
          {base_value}
        </Typography>
        <Typography fontWeight={600} fontSize={8} color="gray">
          {target_value}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default KeyResultProgressBar;
