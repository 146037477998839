import { Chip, Stack, Typography } from '@mui/material';
import { useState } from 'react';


function OkrListOrder(props) {
  const {setObjective} = props;
  const [order, setOrder] = useState('new');

  function orderByNew() {
    setOrder('new');
    setObjective((objectives) =>
      [...objectives].sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
    );
  }

  function orderByHighProgress() {
    setOrder('high_progress');
    setObjective((objectives) =>
      [...objectives].sort(
        (a, b) => b.progress - a.progress
      )
    );
  }

  function orderByLowProgress() {
    setOrder('low_progress');
    setObjective((objectives) =>
      [...objectives].sort(
        (a, b) => a.progress - b.progress
      )
    );
  }

  return (
    <Stack gap={2} direction="row" alignItems="center" marginLeft={1}>
      <Typography variant="label-medium" sx={{color: '#696F8C'}}>
        Ordenar por
      </Typography>
      <Stack gap={1} direction="row">
        <Chip
          label="Mais novo"
          variant={order === 'new' ? 'filled' : 'outlined'}
          onClick={orderByNew}
          size="small"
          sx={
            order === 'new' ? {backgroundColor: '#0F785F', color: '#fff'} : {}
          }
        />
        <Chip
          label="Maior Progresso"
          variant={order === 'high_progress' ? 'filled' : 'outlined'}
          onClick={orderByHighProgress}
          size="small"
          sx={
            order === 'high_progress'
              ? {backgroundColor: '#0F785F', color: '#fff'}
              : {}
          }
        />
        <Chip
          label="Menor Progresso"
          variant={order === 'low_progress' ? 'filled' : 'outlined'}
          onClick={orderByLowProgress}
          size="small"
          sx={
            order === 'low_progress'
              ? {backgroundColor: '#0F785F', color: '#fff'}
              : {}
          }
        />
      </Stack>
    </Stack>
  );
}

export default OkrListOrder;
