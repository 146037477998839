import { Typography } from '@mui/material';
import { differenceInDays, format, parseISO } from 'date-fns';

function compareDates(dateString) {
  const dateFromBackend = parseISO(dateString);
  const today = new Date();

  if (format(dateFromBackend, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
    return {label: 'Check-in feito hoje', color: 'gray'};
  }

  const diffDays = differenceInDays(today, dateFromBackend);

  if (diffDays === 1) {
    return {label: 'Check-in feito ontem', color: 'gray'};
  }

  if (diffDays > 15) {
    return {label: 'Check-in em atraso', color: '#A70F0F'};
  }

  return {label: `Check-in feito há ${diffDays} dias`, color: 'gray'};
}

function KeyResultLastCheckIn(props) {
  const {checkInDate} = props;

  if (!checkInDate) {
    return (
      <Typography color="gray" fontSize={10} fontWeight={700}>
        Sem Check-in
      </Typography>
    );
  }

  const {label, color} = compareDates(checkInDate);
  return (
    <Typography color={color} fontSize={10} fontWeight={700}>
      {label}
    </Typography>
  );
}

export default KeyResultLastCheckIn;
