import { useDeleteObjective } from '@/hooks/objective';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Lock from '@mui/icons-material/Lock';
import { Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useRouter } from 'next/router';
import { ActionsButton } from '../ActionsButton';
import CircularProgressPersonalized from '../CircularProgress/CircularProgress';
import { KeyResultListItem } from '../KeyResultsListItem';
import { ObjectiveForm } from '../ObjectiveForm';

function OkrCard(props) {
  const {objective} = props;
  const {name, progress, visibility_status} = objective;

  const router = useRouter();
  const deleteMutation = useDeleteObjective();
  return (
    <Accordion disableGutters TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row" gap={2} alignItems="center">
            <CircularProgressPersonalized progress={progress} />
            <Typography variant="body1" align="left" fontWeight={600}>
              {name}
            </Typography>
            {visibility_status == 'PV' && (
              <Tooltip
                title={
                  <>
                    <Typography color="inherit">
                      Usuários com acesso:
                    </Typography>
                    {objective.users_visibility.map((user) => (
                      <Typography key={user.id} color="inherit">
                        {' '}
                        - {user.name}
                      </Typography>
                    ))}
                  </>
                }
                arrow
                placement="right"
              >
                <Lock />
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" alignItems="center" marginRight={2}>
            <ActionsButton object={objective} deleteMutation={deleteMutation}>
              <ObjectiveForm objective={objective} />
            </ActionsButton>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {objective?.key_results?.map((kr, index) => (
          <KeyResultListItem kr={kr} index={index} key={index} />
        ))}
        <Divider sx={{marginTop: '16px'}} />
        <Button
          variant="text"
          onClick={() => router.push(`/objetivo/${objective.id}/criar-kr`)}
          startIcon={<AddIcon />}
          sx={{marginTop: 1}}
          size="small"
        >
          novo key result
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}

export default OkrCard;
